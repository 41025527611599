<template>
  <div>
    <v-dialog max-width="600" v-model="dialogs.history">
      <v-card>
        <v-card-title>
          History <v-icon class="ml-3" color="info">mdi-history</v-icon>
        </v-card-title>
        <v-card-text>
          <parcel-history :parcel="activeParcel"></parcel-history>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.card" max-width="600">
      <parcel-detail
        :highlight="false"
        :id="activeParcel"
        @close="dialogs.card = false"
        :showClose="true"
      >
      </parcel-detail>
    </v-dialog>
    <v-fade-transition>
      <v-alert class="my-2" v-if="error" type="error" text>
        {{ error }}
      </v-alert>
    </v-fade-transition>
    <div class="text-right mt-1 mb-n2">
      <v-btn
        v-if="teamsComputed"
        color="primary"
        text
        class="text-capitalize mt-1 v-btn--active"
        @click="summary = !summary"
      >
        {{ summary ? 'Review Team Assignment' : 'Team Summaries' }}
      </v-btn>
      <v-btn
        @click="computeTeamRecommendation()"
        text
        color="primary"
        class="text-capitalize v-btn--active"
        v-else
      >
        Compute Team Recommendation
      </v-btn>
    </div>

    <div class="d-flex justify-space-between align-center">
      <div>
        <v-text-field
          append-icon="mdi-magnify"
          single-line
          label="Search"
          v-model="search"
        ></v-text-field>
      </div>
      <div v-if="parcels.length">
        <v-chip color="primary" v-if="parcels.length"> {{ parcels.length }} parcels </v-chip>
        <v-chip color="success" class="ml-1"> {{ totalAcres(parcels) }} acres </v-chip>
      </div>
    </div>
    <div v-if="summary">
      <v-data-table :headers="summaryHeaders" :items="getSummary()" hide-default-footer>
        <template v-slot:[`item.fillColor`]="{ item }">
          <v-chip :color="item.fillColor" :dark="isDark(item.fillColor)">
            {{ item.fillColor }}
          </v-chip>
        </template>
        <template v-slot:[`item.strokeColor`]="{ item }">
          <v-chip :color="item.strokeColor" :dark="isDark(item.strokeColor)">
            {{ item.strokeColor }}
          </v-chip>
        </template>
        <template v-slot:[`body.append`]>
          <tr>
            <th>Total</th>
            <th>{{ rowTotal(getSummary(), 'assignedParcels') }}</th>
            <th>{{ rowTotal(getSummary(), 'assignedAcres') }}</th>
            <th>{{ rowTotal(getSummary(), 'computedParcels') }}</th>
            <th>{{ rowTotal(getSummary(), 'computedAcres') }}</th>
            <th>{{ rowTotal(getSummary(), 'geoJsonAcres') }}</th>
          </tr>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <v-skeleton-loader type="table" v-if="loading || teamGeoJSONLoading"></v-skeleton-loader>
      <base-data-table
        v-else
        :options.sync="options"
        :search="search"
        :headers="headers"
        :items="parcelsComputed"
      >
        <template v-slot:[`header.team`]="{ header }"
          >{{ header.text }}
          <table-filter
            defaultColor=""
            v-model="teamFilter"
            :items="teamsComputedMap"
          ></table-filter>
        </template>

        <template v-slot:[`header.land`]="{ header }"
          >{{ header.text }}
          <table-filter defaultColor="" v-model="landType" :items="landTypes"></table-filter>
        </template>

        <template v-slot:[`item.number`]="{ item }">
          {{ removePadding(item) }}
        </template>

        <template v-slot:[`item.land`]="{ item }">
          {{ landTypeText(item) }}
        </template>

        <template v-slot:[`item.address`]="{ item }">
          <span v-html="item.address"> </span>
        </template>

        <template v-slot:[`item.parcel_type`]="{ item }">
          <span>
            {{ formatParcelType(item.parcel_type) }}
          </span>
        </template>

        <template v-slot:[`item.key`]="{ item }">
          <div>
            <property-card-key :data="item" :width="80" x-small :small="false" class="py-3" textBtn
              ><template #text><span></span></template
            ></property-card-key>
          </div>
        </template>
        <template v-slot:[`item.team`]="{ item }">
          <update-team
            :data="getParcel(item)"
            @input="updateParcelTeam(item.id, $event)"
          ></update-team>
        </template>
        <template v-slot:[`item.computedTeam`]="{ item }">
          <v-chip
            color="purple"
            dark
            small
            v-show="item.computedTeam"
            @click="updateParcelTeam(item.id, item.computedTeam, true)"
          >
            <v-icon class="mr-2" small icon v-if="item.team !== item.computedTeam">
              mdi-arrow-left
            </v-icon>
            {{ item.computedTeam }}
          </v-chip>
        </template>
        <template v-slot:[`item.lastUpdated`]="{ item }">
          <v-menu offset-y transition="slide-y-transition" min-width="130" max-width="350">
            <template #activator="{ on }">
              <div v-on="on">
                <base-prop-tooltip :tooltip="recentActivityTimestamp(item.recent_activity)">
                  <span :class="{ 'error--text': monitoringIssue(item.recent_activity) }">
                    <span v-if="item.recent_activity">
                      {{ parseActivity(item.recent_activity) }}
                    </span>
                    <small>
                      {{ item.recent_activity | fromNowRecentActivity }}
                    </small>
                  </span>
                </base-prop-tooltip>
              </div>
            </template>
            <v-card v-if="monitoringIssue(item.recent_activity)" outlined color="error">
              <v-card-text class="white--text">
                Issue: {{ monitoringIssue(item.recent_activity, true) }}
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div class="d-flex">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon color="primary" @click="showCardDialog(item)" v-on="on"
                  >mdi-card-text</v-icon
                >
              </template>
              <span>Card View</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon color="secondary" @click="showHistory(item)" v-on="on">mdi-history</v-icon>
              </template>
              <span>History</span>
            </v-tooltip>
          </div>
        </template>
      </base-data-table>
    </div>
  </div>
</template>

<script>
import Land from '@/services/land';
import Parcel from '../../services/parcel';
import { CMS } from '../../services/cms';
import { teamFilter, landFilter, getStreetAddress, teams } from '@/utils/parcel';
import { parseTimestamp } from '@/utils/firebase';
import center from '@turf/center';
import Moment from 'moment';
import tinycolor from 'tinycolor2';
import * as turf from '@turf/turf';
import { parcelTeam } from '../../utils/map';
import { rowTotal } from '../../utils';
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    organization: {
      type: String,
      required: true
    }
  },
  components: {
    PropertyCardKey: () => import('../Parcel/PropertyCardKey.vue'),
    UpdateTeam: () => import('../Parcel/UpdateTeam'),
    ParcelDetail: () => import('../Parcel/DetailById.vue'),
    ParcelHistory: () => import('../Parcel/History.vue'),
    TableFilter: () => import('@/components/Core/TableFilter.vue')
  },
  filters: {
    fromNowRecentActivity(data) {
      Moment.updateLocale('en', {
        relativeTime: {
          future: 'in %s',
          past: '%s ago',
          s: 'seconds',
          ss: '%ss',
          m: 'a minute',
          mm: '%dm',
          h: 'an hour',
          hh: '%dh',
          d: 'a day',
          dd: '%dd',
          M: 'a month',
          MM: '%dM',
          y: 'a year',
          yy: '%dY'
        }
      });
      try {
        const ts = data.timestamp || data.createdAt;
        const res = Moment(parseTimestamp(ts)).fromNow(true);
        return res;
      } catch {
        return '';
      }
    }
  },

  data: () => ({
    error: '',
    search: '',
    landType: '',
    landTypes: Land.landTypes(),
    dialogs: {
      card: false,
      history: false
    },
    loading: true,
    teamFilter: '',
    options: {},
    activeParcel: null,
    headers: [
      { text: 'Parcel ID', value: 'number' },
      { text: 'Address', value: 'address' },
      { text: 'Type', value: 'parcel_type' },
      { text: 'Acres', value: 'acres', align: 'center' },
      { text: 'Key', value: 'key', align: 'center' },
      { text: 'Last Activity', value: 'lastUpdated' },
      { text: 'Reference', value: 'reference' },
      { text: 'Land', value: 'land' },
      { text: 'Team', value: 'team' },
      { text: 'Action', value: 'action' }
    ],
    summaryHeaders: [
      { text: 'Team', value: 'team' },
      { text: 'Assigned Parcels', value: 'assignedParcels' },
      { text: 'Assigned Acres', value: 'assignedAcres' },
      { text: 'Computed Parcels', value: 'computedParcels' },
      { text: 'Computed Acres', value: 'computedAcres' },
      { text: 'GeoJSON Acres', value: 'geoJsonAcres' },
      { text: 'GeoJSON Key', value: 'geoJsonKey' },
      { text: 'Fill Color', value: 'fillColor' },
      { text: 'Stroke Color', value: 'strokeColor' },
      { text: 'Description', value: 'description' }
    ],
    parcels: [],
    teamGeoJSON: [],
    teamGeoJSONLoading: true,
    summary: false,
    teamsComputed: false
  }),

  created() {
    this.fetchTeamGeoJSON();
  },

  computed: {
    teamsComputedMap() {
      return teams(this.parcels, true, true);
    },

    parcelsComputed() {
      let parcels = teamFilter(this.parcels, this.teamFilter);
      parcels = landFilter(parcels, this.landType);

      parcels.map(p => {
        const lastUpdated = (p.lastUpdated =
          p?.recent_activity?.createdAt || p?.recent_activity?.timestamp);
        if (lastUpdated) {
          try {
            p.lastUpdated = parseTimestamp(lastUpdated).getTime();
          } catch {
            p.lastUpdated = 0;
          }
        } else {
          p.lastUpdated = 0;
        }

        p.address = getStreetAddress(p);

        return p;
      });

      return parcels;
    }
  },

  watch: {
    id: {
      handler(id) {
        this.fetchData(id);
      },
      immediate: true
    }
  },

  methods: {
    recentActivityTimestamp(activity) {
      return this.formatTimestamp(activity?.timestamp || activity?.createdAt);
    },
    monitoringIssue(activity, issue = false) {
      if (activity?.type === 'land') {
        if (activity.detail?.issueDescription) {
          return issue ? activity.detail?.issueDescription : true;
        }
      }
      return false;
    },
    computeTeamRecommendation() {
      this.parcels = this.parcels.map(p => {
        try {
          p.computedTeam = parcelTeam(this.teamGeoJSON, p.center || this.getCenter(p));
        } catch {
          p.computedTeam = '';
        }
        return p;
      });

      this.headers.splice(9, 0, { text: 'Computed Team', value: 'computedTeam' });
      this.teamsComputed = true;
    },
    rowTotal,
    totalAcres(parcels, key = 'acres') {
      return this.rowTotal(parcels, key);
    },
    isDark(color) {
      return tinycolor(color).getLuminance() < 0.4;
    },
    getSummary() {
      if (!this.teamGeoJSON || !this.teamGeoJSON.features) {
        return;
      }
      const res = [];
      this.teamGeoJSON.features.forEach(feature => {
        const data = {
          team: feature.properties.Team,
          description: feature.properties.description || '',
          fillColor: feature.properties.fill,
          strokeColor: feature.properties.stroke,
          geoJsonKey: `${this.organization}_TeamGeoJSON`
        };

        try {
          const area = turf.area(feature);
          if (area && !isNaN(area)) {
            data.geoJsonAcres = Number(area / 4047).toFixed(2);
          }
        } catch {
          data.geoJsonAcres = '';
        }

        const assigned = this.parcelsComputed.filter(({ team }) => {
          return team === data.team;
        });

        const computed = this.parcelsComputed.filter(({ computedTeam }) => {
          return computedTeam === data.team;
        });

        data.assignedParcels = assigned.length;
        data.computedParcels = computed.length;
        data.assignedAcres = this.totalAcres(assigned);
        data.computedAcres = this.totalAcres(computed);

        res.push(data);
      });

      return res;
    },
    updateParcelTeam(id, value, db = false) {
      const index = this.parcels.findIndex(p => p.id === id);
      this.parcels[index].team = value;
      if (db) {
        const p = new Parcel({ id });
        p.fetch().then(() => {
          p.update({ team: value });
        });
      }
    },
    getCenter({ geojson }) {
      if (!geojson) {
        return null;
      }
      try {
        const gj = JSON.parse(geojson);
        return center(gj).geometry.coordinates;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    fetchTeamGeoJSON() {
      const cms = new CMS();
      this.error = '';
      cms
        .get({ key: `${this.organization}_TeamGeoJSON`, type: 'geojson' })
        .then(res => {
          if (res.size) {
            const gj = JSON.parse(res.docs[0]?.data()?.geojson);
            gj.features = gj.features.filter(feature => {
              return feature.geometry.type === 'Polygon';
            });
            this.teamGeoJSON = gj;
          } else {
            this.error = 'This organization does not have team GeoJSON';
          }
          this.teamGeoJSONLoading = false;
        })
        .catch(err => {
          this.error = err;
        });
    },
    fetchData(id) {
      const parcel = new Parcel(id);
      parcel.list({ id }).then(data => {
        this.parcels = data;
        this.loading = false;
      });
    },
    formatParcelType(type) {
      const p = new Parcel();
      return p.type(type);
    },
    landTypeText(parcel) {
      const p = new Parcel({ parcel });
      const type = p.getLandType();
      return Land.landType(type)?.shortName || '';
    },
    getParcel(parcel) {
      return {
        ...this.parcels.filter(e => e.id == parcel.id)[0]
      };
    },
    removePadding(parcel) {
      const p = new Parcel({ parcel });
      return p.removePadding();
    },
    getStreetAddress(parcel) {
      const p = new Parcel({ parcel });
      return p.getStreetAddress();
    },
    setActiveParcel(parcel) {
      this.activeParcel = parcel.id;
    },
    showCardDialog(parcel) {
      this.setActiveParcel(parcel);
      this.dialogs.card = true;
    },
    showHistory(parcel) {
      this.setActiveParcel(parcel);
      this.dialogs.history = true;
    },
    updateCurrent(data) {
      this.activeParcel = { ...data };
    }
  }
};
</script>

<style>
</style>
